import { AxiosError } from "axios";
import Api from "../../api/Api";
import { ChannelModel } from "../../models/ChannelModel";
import { PaymentResultInterface } from "./interfaces/payment-result.interface";
import { DonationSingleResultInterface } from "../payment/interfaces/donation-single-result.interface";

export enum ChannelType {
  BANK_TRANSFER,
  MANUAL_BANK_TRANSFER,
  ECHANNEL,
  EWALLET,
  CSTORE,
  UNIVERSA_WALLET,
  VIRTUAL_ACCOUNT,
}

export class ChannelProvider {
  private api: Api;

  constructor() {
    this.api = new Api();
  }

  getPaymentChannels = async (): Promise<PaymentResultInterface> => {
    try {
      let response = await this.api.apiProgram.get("/public/payments", {
        params: {
          company_id: process.env.REACT_APP_COMPANY_ID,
          is_active: true,
          size: 1000,
          page: 0,
          sort: "created_at",
        },
      });
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: PaymentResultInterface = a.response?.data;
      return response;
    }
  };

  getChannels = (): ChannelModel[] => {
    let channels: ChannelModel[] = [];

    channels.push({
      thumbnail: "bca sprite",
      type: ChannelType.BANK_TRANSFER,
      payment_type: "bank_transfer",
      code: "bca",
      title: "BCA",
      subtitle: "BCA Virtual Account",
      hows: [
        {
          name: "ATM BCA",
          steps: [
            "Pada menu utama, pilih Transaksi Lainnya.",
            "Pilih Transfer.",
            "Pilih Ke Rek BCA Virtual Account.",
            "Masukkan Nomor Rekening pembayaran (11 digit) Anda lalu tekan Benar.",
            "Masukkan jumlah tagihan yang akan anda bayar.",
            "Pada halaman konfirmasi transfer akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya.",
          ],
        },
        {
          name: "Klik BCA",
          steps: [
            "Pilih menu Transfer Dana.",
            "Pilih Transfer ke BCA Virtual Account.",
            "Masukkan nomor BCA Virtual Account, atau pilih Dari Daftar Transfer.",
            "Jumlah yang akan ditransfer, nomor rekening dan nama merchant akan muncul di halaman konfirmasi pembayaran, jika informasi benar klik Lanjutkan.",
            "Ambil BCA Token Anda dan masukkan KEYBCA Response APPLI 1 dan Klik Submit.",
            "Transaksi Anda selesai.",
          ],
        },
        {
          name: "m-BCA",
          steps: [
            "Lakukan log in pada aplikasi BCA Mobile.",
            "Pilih menu m-BCA, kemudian masukkan kode akses m-BCA.",
            "Pilih m-Transfer > BCA Virtual Account.",
            "Pilih dari Daftar Transfer, atau masukkan Nomor Virtual Account tujuan.",
            "Masukkan jumlah yang ingin dibayarkan.",
            "Masukkan pin m-BCA.",
            "Pembayaran selesai. Simpan notifikasi yang muncul sebagai bukti pembayaran.",
          ],
        },
      ],
    });

    channels.push({
      thumbnail: "mandiri sprite",
      code: "mandiri",
      type: ChannelType.BANK_TRANSFER,
      payment_type: "echannel",
      title: "MANDIRI",
      subtitle: "Mandiri Virtual Account",
      echannel: {
        bill_info1: "Payment",
        bill_info2: "Online payment",
      },
      hows: [
        {
          name: "ATM Mandiri",
          steps: [
            "Pada menu utama, pilih Bayar/Beli",
            "Pilih Lainnya",
            "Pilih Multi Payment",
            "Masukkan 70012 (kode perusahaan Midtrans) lalu tekan Benar.",
            "Masukkan Kode Pembayaran Anda lalu tekan Benar.",
            "Pada halaman konfirmasi akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya.",
          ],
        },
        {
          name: "Internet Banking",
          steps: [
            "Login ke Internet Banking Mandiri (https://ibank.bankmandiri.co.id/).",
            "Pada menu utama, pilih Bayar, lalu pilih Multi Payment.",
            "Pilih akun Anda di Dari Rekening, kemudian di Penyedia Jasa pilih Midtrans.",
            "Masukkan Kode Pembayaran Anda dan klik Lanjutkan.",
            "Konfirmasi pembayaran Anda menggunakan Mandiri Token.",
          ],
        },
      ],
    });

    channels.push({
      thumbnail: "bni sprite",
      code: "bni",
      type: ChannelType.BANK_TRANSFER,
      payment_type: "bank_transfer",
      title: "BNI",
      subtitle: "BNI Virtual Account",
      hows: [
        {
          name: "ATM BNI",
          steps: [
            "Pada menu utama, pilih Menu Lainnya.",
            "Pilih Transfer.",
            "Pilih Rekening Tabungan.",
            "Pilih Ke Rekening BNI.",
            "Masukkan nomor virtual account dan pilih Tekan Jika Benar.",
            "Masukkan jumlah tagihan yang akan anda bayar secara lengkap. Pembayaran dengan jumlah tidak sesuai akan otomatis ditolak.",
            "Jumlah yang dibayarkan, nomor rekening dan nama Merchant akan ditampilkan. Jika informasi telah sesuai, tekan Ya.",
            "Transaksi Anda sudah selesai.",
          ],
        },
        {
          name: "Internet Banking",
          steps: [
            "Ketik alamat https://ibank.bni.co.id kemudian klik Masuk.",
            "Silakan masukkan User ID dan Password.",
            "Klik menu Transfer kemudian pilih Tambah Rekening Favorit.",
            "Masukkan nama, nomor rekening, dan email, lalu klik Lanjut.",
            "Masukkan Kode Otentikasi dari token Anda dan klik Lanjut.",
            "Kembali ke menu utama dan pilih Transfer lalu Transfer Antar Rekening BNI.",
            "Pilih rekening yang telah Anda favoritkan sebelumnya di Rekening Tujuan lalu lanjutkan pengisian, dan tekan Lanjut.",
            "Pastikan detail transaksi Anda benar, lalu masukkan Kode Otentikasi dan tekan Lanjut.",
          ],
        },
        {
          name: "Mobile Banking",
          steps: [
            "Buka aplikasi BNI Mobile Banking dan login",
            "Pilih menu Transfer",
            "Pilih menu Virtual Account Billing",
            "Pilih rekening debit yang akan digunakan",
            "Pilih menu Input Baru dan masukkan 16 digit nomor Virtual Account",
            "Informasi tagihan akan muncul pada halaman validasi",
            "Jika informasi telah sesuai, masukkan Password Transaksi dan klik Lanjut",
            "Transaksi Anda akan diproses",
          ],
        },
      ],
    });

    channels.push({
      thumbnail: "bri sprite",
      code: "bri",
      type: ChannelType.BANK_TRANSFER,
      payment_type: "bank_transfer",
      title: "BRI",
      subtitle: "BRI Virtual Account",
      hows: [
        {
          name: "ATM BRI",
          steps: [
            "Pilih menu utama, pilih Transaksi Lain.",
            "Pilih Pembayaran.",
            "Pilih Lainnya.",
            "Pilih BRIVA.",
            "Masukkan Nomor BRIVA pelanggan dan pilih Benar.",
            "Jumlah pembayaran, nomor BRIVA dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Ya.",
            "Pembayaran telah selesai. Simpan bukti pembayaran Anda.",
          ],
        },
        {
          name: "IB BRI",
          steps: [
            "Masuk pada Internet Banking BRI.",
            "Pilih menu Pembayaran & Pembelian.",
            "Pilih sub menu BRIVA.",
            "Masukkan Nomor BRIVA.",
            "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Kirim.",
            "Masukkan password dan mToken, pilih Kirim.",
            "Pembayaran telah selesai. Untuk mencetak bukti transaksi, pilih Cetak.",
          ],
        },
        {
          name: "BRImo",
          steps: [
            "Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI.",
            "Pilih Pembayaran, lalu pilih BRIVA.",
            "Masukkan nomor BRIVA.",
            "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Continue.",
            "Masukkan Mobile Banking BRI PIN, pilih Ok..",
            "Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran.",
          ],
        },
      ],
    });

    channels.push({
      thumbnail: "permata sprite",
      type: ChannelType.BANK_TRANSFER,
      payment_type: "bank_transfer",
      code: "permata",
      title: "PERMATA",
      subtitle: "Permata Virtual Account",
      hows: [
        {
          name: "ATM Permata/Bersama",
          steps: [
            "Pada menu utama, pilih Transaksi Lainnya.",
            "Pilih Pembayaran.",
            "Pilih Pembayaran Lainnya.",
            "Pilih Virtual Account.",
            "Masukkan 16 digit No. Virtual Account yang dituju, lalu tekan Benar.",
            "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening, & nama Merchant. Jika informasi telah sesuai tekan Benar.",
            "Pilih rekening pembayaran Anda dan tekan Benar.",
          ],
        },
      ],
    });

    channels.push({
      thumbnail: "bank-transfer sprite",
      type: ChannelType.BANK_TRANSFER,
      payment_type: "bank_transfer",
      code: "permata",
      title: "BANK Lainnya (Prima/ATM Bersama/Alto)",
      subtitle: "Bayar di jaringan ATM Bank Lainnya",
      additional_text: ["013"],
      hows: [
        {
          name: "Prima",
          steps: [
            "Pada menu utama, pilih Transaksi Lainnya.",
            "Pilih Transfer.",
            "Pilih Rek Bank Lain.",
            "Masukkan nomor 013 (kode Bank Permata) lalu tekan Benar.",
            "Masukkan jumlah tagihan yang akan Anda bayar secara lengkap, pembayaran dengan jumlah tidak sesuai akan otomatis ditolak.",
            "Masukkan 16 digit No. Rekening pembayaran lalu tekan Benar.",
            "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening & nama Merchant. Jika informasi telah sesuai tekan Benar.",
          ],
        },
        {
          name: "ATM Bersama",
          steps: [
            "Pada menu utama, pilih Transaksi Lainnya.",
            "Pilih Transfer.",
            "Pilih Antar Bank Online.",
            "Masukkan 013 dan 16 digit No. Rekening.",
            "Masukkan jumlah tagihan yang akan Anda bayar secara lengkap, Pembayaran dengan jumlah tidak sesuai akan otomatis ditolak.",
            "Kosongkan No. Referensi, lalu tekan Benar.",
            "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening & nama Merchant. Jika informasi telah sesuai tekan Benar.",
          ],
        },
        {
          name: "Alto",
          steps: [
            "Pada menu utama, pilih Transaksi Lainnya.	",
            "Pilih Transaksi Pembayaran.",
            "Pilih Lain-lain.",
            "Pilih Pembayaran Virtual Account.",
            "Masukkan 16 digit No. Rekening pembayaran lalu tekan Benar.",
            "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening & nama Merchant. Jika informasi telah sesuai tekan Benar.",
            "Pilih rekening pembayaran Anda dan tekan Benar.",
          ],
        },
      ],
    });

    channels.push({
      thumbnail: "gopay sprite",
      type: ChannelType.EWALLET,
      payment_type: "gopay",
      code: "gopay",
      title: "GoPay",
      subtitle: "Pembayaran melalui GoPay",
      additional_text: [
        "Scan kode QR berikut dengan aplikasi Gojek.",
        "Buka Aplikasi Gojek",
      ],
      hows: [
        {
          name: "GoPay",
          steps: [
            "Buka aplikasi Gojek atau e-wallet lain Anda.",
            "Pindai kode QR yang ada pada monitor Anda.",
            "Periksa detail transaksi Anda pada aplikasi, lalu tap tombol Bayar.",
            "Masukkan PIN Anda.",
            "Transaksi Anda telah selesai.",
          ],
        },
      ],
    });

    channels.push({
      thumbnail: "shopee sprite",
      type: ChannelType.EWALLET,
      payment_type: "shopeepay",
      code: "shopee",
      title: "ShopeePay",
      subtitle: "Pembayaran melalui ShopeePay",
      additional_text: [
        "Klik tombol dibawah untuk menuju aplikasi Shopee",
        "Buka Aplikasi Shopee",
      ],
      hows: [
        {
          name: "ShopeePay",
          steps: [
            "Buka aplikasi Shopee atau e-wallet lain Anda.",
            "Periksa detail transaksi Anda pada aplikasi, lalu tap tombol Bayar.",
            "Masukkan PIN Anda.",
            "Transaksi Anda telah selesai.",
          ],
        },
      ],
    });

    channels.push({
      thumbnail: "alfamart sprite",
      type: ChannelType.CSTORE,
      code: "alfamart",
      payment_type: "cstore",
      title: "ALFAMART",
      subtitle: "Pembayaran melalui Gerai Alfamart, Alfamidi, atau Dan+Dan.",
      hows: [
        {
          name: "Gerai Alfamart, Alfamidi, atau Dan+Dan",
          steps: [
            "Silakan pergi ke gerai Alfamart, Alfamidi, or Dan+Dan terdekat dan berikanlah nomorKode Pembayaran Anda ke kasir.",
            "Kasir akan mengkonfirmasi transaksi dengan menanyakan jumlah transaksi dan nama merchant.",
            "Konfirmasikan pembayaran Anda ke kasir.",
            "Transaksi Anda berhasil! Anda akan mendapatkan email konfirmasi pembayaran dan simpanlah struk transaksi Anda.",
          ],
        },
      ],
    });

    return channels;
  };
}
