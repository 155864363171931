import { Card, Spin, notification } from "antd";
import React from "react";
import {
  ChannelContext,
  ChannelContextProvider,
  ChannelContextType,
} from "../../contexts/channels/ChannelContext";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ChannelModel } from "../../models/ChannelModel";
import "./channel.css";
import {
  DonationSingleCartResultInterface,
  DonationSingleResultInterface,
} from "../../contexts/payment/interfaces/donation-single-result.interface";
import {
  PaymentContext,
  PaymentContextProvider,
  PaymentContextType,
} from "../../contexts/payment/PaymentContext";
// import { NotificationPlacement } from "antd/lib/notification";
import { Typography } from 'antd'
import { NotificationPlacement } from "antd/lib/notification";
export interface PaymentInterfaceParam {
  item: ChannelModel;
  encrypt?: string;
  donationResult?: DonationSingleResultInterface;
  donationCartResult?: DonationSingleCartResultInterface;
}

function Channel() {
  return (
    <ChannelContextProvider>
      <PaymentContextProvider>
        <ChannelState />
      </PaymentContextProvider>
    </ChannelContextProvider>
  );
}

function ChannelState() {
  const {
    channels,
    midtransChannels,
    ipaymuChannels,
    walletChannels,
    nicepayChannels,
    ifortepayChannels,
    ryFlipChannels
  } = React.useContext(ChannelContext) as ChannelContextType;
  const [encrypt, setEncrypt] = React.useState<string>("");
  const [donationResult, setDonationResult] =
    React.useState<DonationSingleResultInterface>(undefined);
  const { getDonationDetailCart, donationDetailCart, donationDetailLoading } =
    React.useContext(PaymentContext) as PaymentContextType;
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.error({
      message: `Gagal`,
      description: "Anda harus login untuk menggunakan fitur wallet",
      placement,
    });
  };
  const openNotificationTemp = (placement: NotificationPlacement) => {
    api.error({
      message: `Mohon Maaf`,
      description:
        "Untuk keranjang donasi yang lebih dari 1, silahkan gunakan metode pembayaran lain, untuk sementara metode pembayaran Transfer Manual belum bisa digunakan untuk keranjang donasi lebih dari 1.",
      placement,
    });
  };

  React.useEffect(() => {
    if (params) {
      if (params["invoice_code"] !== undefined) {
        getDonationDetailCart(params["invoice_code"] as string);
      }
    }
  }, [params]);

  React.useEffect(() => {
    if (encrypt !== "") {
      getDonationDetailCart(encrypt);
    }
  }, [encrypt]);

  React.useEffect(() => {
    setEncrypt(location.search.replace("?token=", ""));
  }, [location]);

  return (
    <div
      style={{
        margin: 20,
      }}
    >
      {contextHolder}
      <Spin tip="Loading..." spinning={donationDetailLoading}>
        {midtransChannels?.length > 0 && <Typography.Title level={3}>Midtrans VA & Merchants</Typography.Title>}
        <div style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}>
          {midtransChannels.map((item, index) => {
            return (
              <button
                key={index}
                style={{
                  marginBottom: 2,
                  cursor: "pointer",
                  padding: 5,
                  border: '1px solid #ddd',
                  borderRadius: 8,
                  backgroundColor: 'white'
                }}
                onClick={(e) => {
                  e.preventDefault();

                  const donationDetailSingle: DonationSingleResultInterface = {
                    status: donationDetailCart?.status,
                    message: donationDetailCart.message,
                    data: donationDetailCart.data[0],
                    error: donationDetailCart.error,
                  };

                  let param: PaymentInterfaceParam = {
                    item: item,
                    donationResult: donationDetailSingle,
                    donationCartResult: donationDetailCart,
                  };

                  if (donationDetailCart.data?.length > 1) {
                    history.push({
                      pathname: "/payment-midtrans-cart",
                      state: param,
                    });
                  } else {
                    history.push({
                      pathname: "/payment-midtrans",
                      state: param,
                    });
                  }
                }}
              >
                <div style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <div style={{
                    paddingLeft: 10,
                    paddingRight: 10
                  }}>
                    <span className={`${item.thumbnail}`}></span>
                  </div>
                  <span style={{
                    marginLeft: 10,
                    fontSize: '1rem',
                    fontWeight: 'bold'
                  }}>{item.title}</span>
                </div>
              </button>
            );
          })}
        </div>

        {ipaymuChannels?.length > 0 && <h3>i-Paymu VA & Merchants</h3>}
        <div style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}>
          {ipaymuChannels.map((item, index) => {
            return (
              <button
                key={index}
                style={{
                  marginBottom: 2,
                  cursor: "pointer",
                  padding: 5,
                  border: '1px solid #ddd',
                  borderRadius: 8,
                  backgroundColor: 'white'
                }}
                onClick={(e) => {
                  e.preventDefault();

                  const donationDetailSingle: DonationSingleResultInterface = {
                    status: donationDetailCart?.status,
                    message: donationDetailCart.message,
                    data: donationDetailCart.data[0],
                    error: donationDetailCart.error,
                  };

                  let param: PaymentInterfaceParam = {
                    item: item,
                    donationResult: donationDetailSingle,
                    donationCartResult: donationDetailCart,
                  };

                  if (donationDetailCart.data?.length > 1) {
                    history.push({
                      pathname: "/payment-ipaymu-cart",
                      state: param,
                    });
                  } else {
                    history.push({
                      pathname: "/payment-ipaymu",
                      state: param,
                    });
                  }
                }}
              >
                <div style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <div style={{
                    paddingLeft: 10,
                    paddingRight: 10
                  }}>
                    <span className={`${item.thumbnail}`}></span>
                  </div>
                  <span style={{
                    marginLeft: 10,
                    fontSize: '1rem',
                    fontWeight: 'bold'
                  }}>{item.subtitle}</span>
                </div>
                {/* <Card.Meta
                // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.title}
                description={item.subtitle}
              /> */}
              </button>
            );
          })}
        </div>

        {nicepayChannels?.length > 0 && <h3>NICEPAY VA & Merchants</h3>}
        {nicepayChannels.map((item, index) => {
          return (
            <Card
              key={index}
              style={{
                marginBottom: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();

                const donationDetailSingle: DonationSingleResultInterface = {
                  status: donationDetailCart?.status,
                  message: donationDetailCart.message,
                  data: donationDetailCart.data[0],
                  error: donationDetailCart.error,
                };

                let param: PaymentInterfaceParam = {
                  item: item,
                  donationResult: donationDetailSingle,
                  donationCartResult: donationDetailCart,
                };

                if (donationDetailCart.data?.length > 1) {
                  history.push({
                    pathname: "/payment-nicepay-cart",
                    state: param,
                  });
                } else {
                  history.push({
                    pathname: "/payment-nicepay",
                    state: param,
                  });
                }
              }}
            >
              <Card.Meta
                // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.title}
                description={item.subtitle}
              />
            </Card>
          );
        })}

        {ifortepayChannels?.length > 0 && <h3>iFortePay</h3>}
        {ifortepayChannels.map((item, index) => {
          return (
            <Card
              key={index}
              style={{
                marginBottom: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();

                const donationDetailSingle: DonationSingleResultInterface = {
                  status: donationDetailCart?.status,
                  message: donationDetailCart.message,
                  data: donationDetailCart.data[0],
                  error: donationDetailCart.error,
                };

                let param: PaymentInterfaceParam = {
                  item: item,
                  donationResult: donationDetailSingle,
                  donationCartResult: donationDetailCart,
                };

                if (donationDetailCart.data?.length > 1) {
                  history.push({
                    pathname: "/payment-ifortepay-cart",
                    state: param,
                  });
                } else {
                  history.push({
                    pathname: `/payment-ifortepay`,
                    state: param,
                  });
                }
              }}
            >
              <Card.Meta
                // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.title}
                description={item.subtitle}
              />
            </Card>
          );
        })}
        {ryFlipChannels?.length > 0 && <Typography.Title level={3}>Online</Typography.Title>}
        <div style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}>
          {ryFlipChannels.map((item, index) => {
            return (
              <button
                key={index}
                style={{
                  marginBottom: 2,
                  cursor: "pointer",
                  padding: 5,
                  border: '1px solid #ddd',
                  borderRadius: 8,
                  backgroundColor: 'white'
                }}
                onClick={(e) => {
                  e.preventDefault();

                  const donationDetailSingle: DonationSingleResultInterface = {
                    status: donationDetailCart?.status,
                    message: donationDetailCart.message,
                    data: donationDetailCart.data[0],
                    error: donationDetailCart.error,
                  };

                  let param: PaymentInterfaceParam = {
                    item: item,
                    donationResult: donationDetailSingle,
                    donationCartResult: donationDetailCart,
                  };

                  // if (donationDetailCart.data?.length > 1) {
                  //   history.push({
                  //     pathname: "/payment-ifortepay-cart",
                  //     state: param,
                  //   });
                  // } else {
                  //   history.push({
                  //     pathname: `/payment-ry-flip`,
                  //     state: param,
                  //   });
                  // }

                  history.push({
                    pathname: `/payment-ry-flip`,
                    state: param,
                  });
                }}
              >
                <div style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <div style={{
                    paddingLeft: 10,
                    paddingRight: 10
                  }}>
                    <span className={`${item.thumbnail}`}></span>
                  </div>
                  <span style={{
                    marginLeft: 10,
                    fontSize: '1rem',
                    fontWeight: 'bold'
                  }}>{item.subtitle}</span>
                </div>
                {/* <Card.Meta
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.subtitle}
                description={item.subtitle}
              /> */}
              </button>
            );
          })}
        </div>

        {channels?.length > 0 ? <Typography.Title level={3} style={{ marginTop: 10 }}>Transfer Manual</Typography.Title> : null}
        <div style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}>
          {channels.map((item, index) => {
            return (
              <button
                key={item.code}
                style={{
                  marginBottom: 2,
                  cursor: "pointer",
                  padding: 5,
                  border: '1px solid #ddd',
                  borderRadius: 8,
                  backgroundColor: 'white'
                }}
                onClick={(e) => {
                  e.preventDefault();

                  const donationDetailSingle: DonationSingleResultInterface = {
                    status: donationDetailCart?.status,
                    message: donationDetailCart?.message,
                    data: donationDetailCart?.data[0],
                    error: donationDetailCart?.error,
                  };

                  let param: PaymentInterfaceParam = {
                    item: item,
                    donationResult: donationDetailSingle,
                    donationCartResult: donationDetailCart,
                  };

                  if (donationDetailCart?.data?.length > 1) {
                    openNotificationTemp("topRight");
                    // history.push({
                    //   pathname: "/payment-cart",
                    //   state: param,
                    // });
                  } else {
                    history.push({
                      pathname: "/payment",
                      state: param,
                    });
                  }
                }}
              >
                <div style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <div style={{
                    paddingLeft: 10,
                    paddingRight: 10
                  }}>
                    <span className={`${item.thumbnail}`}></span>
                  </div>
                  <span style={{
                    marginLeft: 10,
                    fontSize: '1rem',
                    fontWeight: 'bold'
                  }}>{item.title}</span>
                </div>
                {/* <Card.Meta
                  avatar={<span className={`${item.thumbnail}`}></span>}
                  title={item.title}
                  description={item.subtitle}
                /> */}
              </button>
            );
          })}
        </div>

        {walletChannels?.length > 0 ? <Typography.Title level={3}>Wallet</Typography.Title> : null}
        <div style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}>
          {walletChannels.map((item, index) => {
            return (
              <button
                key={item.code}
                style={{
                  marginBottom: 2,
                  cursor: "pointer",
                  padding: 5,
                  border: '1px solid #ddd',
                  borderRadius: 8,
                  backgroundColor: 'white'
                }}
                onClick={(e) => {
                  e.preventDefault();

                  if (donationDetailCart?.data[0]?.created_by !== "") {
                    let param: PaymentInterfaceParam = {
                      item: item,
                      donationResult: donationDetailCart[0],
                      donationCartResult: donationDetailCart,
                    };

                    history.push({
                      pathname: `/payment-wallet-v2/${encrypt}`,
                      state: param,
                    });
                  } else {
                    openNotification("topRight");
                  }
                }}
              >
                <div style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <div style={{
                    paddingLeft: 10,
                    paddingRight: 10
                  }}>
                    <span className={`${item.thumbnail}`}></span>
                  </div>
                  <span style={{
                    marginLeft: 10,
                    fontSize: '1rem',
                    fontWeight: 'bold'
                  }}>{item.title}</span>
                </div>
                {/* <Card.Meta
                  // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                  avatar={<span className={`${item.thumbnail}`}></span>}
                  title={item.title}
                  description={item.subtitle}
                /> */}
              </button>
            );
          })}
        </div>
      </Spin>
    </div>
  );
}

export default Channel;
