import { useContext, useEffect, useState } from "react";
import { PaymentContext, PaymentContextProvider, PaymentContextType } from "../../contexts/payment/PaymentContext";
import { ChannelModel } from "../../models/ChannelModel";
import { useHistory, useLocation } from "react-router-dom";
import { DonationSingleCartResultInterface, DonationSingleResultInterface } from "../../contexts/payment/interfaces/donation-single-result.interface";
import { PaymentInterfaceParam } from "../channels/Channel";
import { Card, Empty, message, Skeleton, Tabs } from "antd";
import { thousandSeparator } from "../../helpers/thousand_separator";
import { ChannelType } from "../../contexts/channels/ChannelProvider";
import axios from "axios";
import { CompanySingleResultInterface } from "../../models/CompanyModel";

function PaymentRyFlip() {
  return (
    <PaymentContextProvider>
      <PaymentState />
    </PaymentContextProvider>
  );
}

function PaymentState() {
  const [channel, setChannel] = useState<ChannelModel>();
  const [donationCartResult, setDonationCartResult] =
    useState<DonationSingleCartResultInterface>(undefined);
  const {
    initPayment,
    doPaymentFlip,
    paymentResultRyFlip,
    // transactionStatus,
    // transactionStatusModel,
    getDonationCartDetailForStatus,
    donationCartDetailForStatus,
    setDonationCartResultV2
  } = useContext(PaymentContext) as PaymentContextType
  const location = useLocation();

  useEffect(() => {
    if (donationCartResult !== undefined) {
      const interval = setInterval(() => {
        getDonationCartDetailForStatus(donationCartResult.data?.[0]?.invoice_code_parent as string);
      }, 5000);

      // Stop the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [donationCartResult]);

  useEffect(() => {
    if (location) {
      setChannel((location.state as PaymentInterfaceParam)?.item);
      setDonationCartResultV2(
        (location.state as PaymentInterfaceParam)?.donationCartResult
      );
      setDonationCartResult(
        (location.state as PaymentInterfaceParam)?.donationCartResult
      );
    }
  }, [location]);

  useEffect(() => {
    if (donationCartResult !== undefined && channel !== undefined) {
      for (const donation of donationCartResult.data) {
        initPayment(donation?.id, channel?.id, "");
      }
      doPaymentFlip(channel)
    }
  }, [donationCartResult, channel]);

  useEffect(() => {
    if (donationCartDetailForStatus !== undefined) {
      if (
        donationCartDetailForStatus?.data?.[0]?.status === "settlement" ||
        donationCartDetailForStatus?.data?.[0]?.status === "capture"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            console.log(response);
            const url = `${response?.data?.platform_donation}/payment/success?order_id=${donationCartDetailForStatus?.data?.[0]?.order_id}&result=success`;
            window.location.href = url;
          });
      } else if (
        donationCartDetailForStatus?.data?.[0]?.status === "cancel" ||
        donationCartDetailForStatus?.data?.[0]?.status === "deny"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/failed?order_id=${donationCartDetailForStatus?.data?.[0]?.order_id}&result=failed`;
            window.location.href = url;
          });
      } else if (donationCartDetailForStatus?.data?.[0]?.status === "expire") {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/timeout?order_id=${donationCartDetailForStatus?.data?.[0]?.order_id}&result=expire`;
            window.location.href = url;
          });
      }
    }
  }, [donationCartDetailForStatus])

  return (
    <div
      style={{ margin: 20 }}>
      <Skeleton loading={false}>
        <h1>{channel?.title}</h1>
        <h2>{channel?.subtitle}</h2>
        {paymentResultRyFlip?.status &&
          paymentResultRyFlip?.data?.success ? (
          <>
            <Card
              hoverable
              style={{
                marginBottom: 10,
              }}
            >
              <p className="amount">Jumlah</p>
              <div
                style={{
                  float: "right",
                }}
              >
                <span className="amount-rp">Rp </span>
                <span className="amount-number">
                  {thousandSeparator(paymentResultRyFlip?.data?.data?.amount ?? 0)}
                </span>
              </div>
            </Card>
            {/* {channel?.type === ChannelType.EWALLET && (
              <Card
                hoverable
                style={{
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                <span className="account-number-text">
                  {channel.additional_text ? channel.additional_text[0] : ""}
                </span>
                <br />
                {qrCode !== undefined ? (
                  <div>
                    <QRCode value={paymentResultIpaymu?.data?.Data?.QrString} />
                    <Divider />
                    <img
                      src="/assets/qris-bpd-bali.png"
                      style={{ width: "13em" }}
                      alt="QRIS iPaymu"
                    />
                    <br />
                    <img
                      src="/assets/scan_qris.png"
                      style={{ width: "15em" }}
                      alt="QRIS iPaymu"
                    />
                  </div>
                ) : null}
                {deeplink !== undefined && (
                  <a
                    className="deeplink"
                    target={"_blank"}
                    rel="noreferrer"
                    href={deeplink}
                  >
                    {channel.additional_text
                      ? channel.additional_text[1]
                      : "Error"}
                  </a>
                )}
              </Card>
            )} */}
            {channel?.type === ChannelType.VIRTUAL_ACCOUNT ? (
              <Card
                style={{
                  marginBottom: 10,
                }}
              >
                <div style={{ float: "left" }}>
                  <span className="account-number-text-block">
                    Nomor Rekening
                  </span>
                  <span className="account-number">{paymentResultRyFlip?.data?.data?.account_number}</span>
                  <br />
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      message.info(`Nomor Rekening berhasil di salin.`);
                      navigator.clipboard.writeText(paymentResultRyFlip?.data?.data?.account_number);
                    }}
                  >
                    Salin Nomor Rekening
                  </a>
                </div>
              </Card>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={paymentResultRyFlip?.data?.message}
          />
        )}
      </Skeleton>

      <Card hoverable title={"Flip"} style={{ marginBottom: 10 }}>
        <span className="transaction-detail-text">Link Pembayaran (Flip)</span>
        <span className="transaction-detail-description">
          <a
            href={paymentResultRyFlip?.data?.data?.payment_url}
            target="_blank"
            rel="noreferrer">
            Klik link ini jika ingin membayar melalui Flip
          </a>
        </span>
      </Card>

      <Card hoverable title={"Detail Transaksi"} style={{ marginBottom: 10 }}>
        <span className="transaction-detail-text">Nama Program</span>
        <span className="transaction-detail-description">
          {paymentResultRyFlip?.data?.data?.title}
        </span>
      </Card>

      {channel?.hows?.length > 0 ? (
        <Card hoverable title={"Cara Pembayaran"} style={{ marginBottom: 10 }}>
          <Tabs>
            {channel?.hows?.map((item, index) => (
              <Tabs.TabPane tab={item.name} key={index}>
                {item.steps?.map((item, index) => (
                  <p key={index}>
                    {index + 1}. {item}
                  </p>
                ))}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
}



export default PaymentRyFlip;