import { ChannelModel } from "../../../models/ChannelModel";
import { ChannelType } from "../ChannelProvider";

export const BCAVAMidtrans: ChannelModel = {
  thumbnail: "bca sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "bank_transfer",
  code: "bca",
  title: "BCA",
  subtitle: "BCA Virtual Account",
  hows: [
    {
      name: "ATM BCA",
      steps: [
        "Pada menu utama, pilih Transaksi Lainnya.",
        "Pilih Transfer.",
        "Pilih Ke Rek BCA Virtual Account.",
        "Masukkan Nomor Rekening pembayaran (11 digit) Anda lalu tekan Benar.",
        "Masukkan jumlah tagihan yang akan anda bayar.",
        "Pada halaman konfirmasi transfer akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya.",
      ],
    },
    {
      name: "Klik BCA",
      steps: [
        "Pilih menu Transfer Dana.",
        "Pilih Transfer ke BCA Virtual Account.",
        "Masukkan nomor BCA Virtual Account, atau pilih Dari Daftar Transfer.",
        "Jumlah yang akan ditransfer, nomor rekening dan nama merchant akan muncul di halaman konfirmasi pembayaran, jika informasi benar klik Lanjutkan.",
        "Ambil BCA Token Anda dan masukkan KEYBCA Response APPLI 1 dan Klik Submit.",
        "Transaksi Anda selesai.",
      ],
    },
    {
      name: "m-BCA",
      steps: [
        "Lakukan log in pada aplikasi BCA Mobile.",
        "Pilih menu m-BCA, kemudian masukkan kode akses m-BCA.",
        "Pilih m-Transfer > BCA Virtual Account.",
        "Pilih dari Daftar Transfer, atau masukkan Nomor Virtual Account tujuan.",
        "Masukkan jumlah yang ingin dibayarkan.",
        "Masukkan pin m-BCA.",
        "Pembayaran selesai. Simpan notifikasi yang muncul sebagai bukti pembayaran.",
      ],
    },
  ],
};

export const MANDIRIVAMidtrans: ChannelModel = {
  thumbnail: "mandiri sprite",
  code: "mandiri",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "echannel",
  title: "MANDIRI",
  subtitle: "Mandiri Virtual Account",
  echannel: {
    bill_info1: "Payment",
    bill_info2: "Online payment",
  },
  hows: [
    {
      name: "ATM Mandiri",
      steps: [
        "Pada menu utama, pilih Bayar/Beli",
        "Pilih Lainnya",
        "Pilih Multi Payment",
        "Masukkan 70012 (kode perusahaan Midtrans) lalu tekan Benar.",
        "Masukkan Kode Pembayaran Anda lalu tekan Benar.",
        "Pada halaman konfirmasi akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya.",
      ],
    },
    {
      name: "Internet Banking",
      steps: [
        "Login ke Internet Banking Mandiri (https://ibank.bankmandiri.co.id/).",
        "Pada menu utama, pilih Bayar, lalu pilih Multi Payment.",
        "Pilih akun Anda di Dari Rekening, kemudian di Penyedia Jasa pilih Midtrans.",
        "Masukkan Kode Pembayaran Anda dan klik Lanjutkan.",
        "Konfirmasi pembayaran Anda menggunakan Mandiri Token.",
      ],
    },
  ],
};

export const BNIVAMidtrans: ChannelModel = {
  thumbnail: "bni sprite",
  code: "bni",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "bank_transfer",
  title: "BNI",
  subtitle: "BNI Virtual Account",
  hows: [
    {
      name: "ATM BNI",
      steps: [
        "Pada menu utama, pilih Menu Lainnya.",
        "Pilih Transfer.",
        "Pilih Rekening Tabungan.",
        "Pilih Ke Rekening BNI.",
        "Masukkan nomor virtual account dan pilih Tekan Jika Benar.",
        "Masukkan jumlah tagihan yang akan anda bayar secara lengkap. Pembayaran dengan jumlah tidak sesuai akan otomatis ditolak.",
        "Jumlah yang dibayarkan, nomor rekening dan nama Merchant akan ditampilkan. Jika informasi telah sesuai, tekan Ya.",
        "Transaksi Anda sudah selesai.",
      ],
    },
    {
      name: "Internet Banking",
      steps: [
        "Ketik alamat https://ibank.bni.co.id kemudian klik Masuk.",
        "Silakan masukkan User ID dan Password.",
        "Klik menu Transfer kemudian pilih Tambah Rekening Favorit.",
        "Masukkan nama, nomor rekening, dan email, lalu klik Lanjut.",
        "Masukkan Kode Otentikasi dari token Anda dan klik Lanjut.",
        "Kembali ke menu utama dan pilih Transfer lalu Transfer Antar Rekening BNI.",
        "Pilih rekening yang telah Anda favoritkan sebelumnya di Rekening Tujuan lalu lanjutkan pengisian, dan tekan Lanjut.",
        "Pastikan detail transaksi Anda benar, lalu masukkan Kode Otentikasi dan tekan Lanjut.",
      ],
    },
    {
      name: "Mobile Banking",
      steps: [
        "Buka aplikasi BNI Mobile Banking dan login",
        "Pilih menu Transfer",
        "Pilih menu Virtual Account Billing",
        "Pilih rekening debit yang akan digunakan",
        "Pilih menu Input Baru dan masukkan 16 digit nomor Virtual Account",
        "Informasi tagihan akan muncul pada halaman validasi",
        "Jika informasi telah sesuai, masukkan Password Transaksi dan klik Lanjut",
        "Transaksi Anda akan diproses",
      ],
    },
  ],
};

export const BRIVAMidtrans: ChannelModel = {
  thumbnail: "bri sprite",
  code: "bri",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "bank_transfer",
  title: "BRI",
  subtitle: "BRI Virtual Account",
  hows: [
    {
      name: "ATM BRI",
      steps: [
        "Pilih menu utama, pilih Transaksi Lain.",
        "Pilih Pembayaran.",
        "Pilih Lainnya.",
        "Pilih BRIVA.",
        "Masukkan Nomor BRIVA pelanggan dan pilih Benar.",
        "Jumlah pembayaran, nomor BRIVA dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Ya.",
        "Pembayaran telah selesai. Simpan bukti pembayaran Anda.",
      ],
    },
    {
      name: "IB BRI",
      steps: [
        "Masuk pada Internet Banking BRI.",
        "Pilih menu Pembayaran & Pembelian.",
        "Pilih sub menu BRIVA.",
        "Masukkan Nomor BRIVA.",
        "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Kirim.",
        "Masukkan password dan mToken, pilih Kirim.",
        "Pembayaran telah selesai. Untuk mencetak bukti transaksi, pilih Cetak.",
      ],
    },
    {
      name: "BRImo",
      steps: [
        "Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI.",
        "Pilih Pembayaran, lalu pilih BRIVA.",
        "Masukkan nomor BRIVA.",
        "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Continue.",
        "Masukkan Mobile Banking BRI PIN, pilih Ok..",
        "Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran.",
      ],
    },
  ],
};

export const PERTAMAVAMidtrans: ChannelModel = {
  thumbnail: "permata sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "bank_transfer",
  code: "permata",
  title: "PERMATA",
  subtitle: "Permata Virtual Account",
  hows: [
    {
      name: "ATM Permata/Bersama",
      steps: [
        "Pada menu utama, pilih Transaksi Lainnya.",
        "Pilih Pembayaran.",
        "Pilih Pembayaran Lainnya.",
        "Pilih Virtual Account.",
        "Masukkan 16 digit No. Virtual Account yang dituju, lalu tekan Benar.",
        "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening, & nama Merchant. Jika informasi telah sesuai tekan Benar.",
        "Pilih rekening pembayaran Anda dan tekan Benar.",
      ],
    },
  ],
};

export const BANKOTHERVAMidtrans: ChannelModel = {
  thumbnail: "bank-transfer sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "bank_transfer",
  code: "permata",
  title: "BANK Lainnya (Prima/ATM Bersama/Alto)",
  subtitle: "Bayar di jaringan ATM Bank Lainnya",
  additional_text: ["013"],
  hows: [
    {
      name: "Prima",
      steps: [
        "Pada menu utama, pilih Transaksi Lainnya.",
        "Pilih Transfer.",
        "Pilih Rek Bank Lain.",
        "Masukkan nomor 013 (kode Bank Permata) lalu tekan Benar.",
        "Masukkan jumlah tagihan yang akan Anda bayar secara lengkap, pembayaran dengan jumlah tidak sesuai akan otomatis ditolak.",
        "Masukkan 16 digit No. Rekening pembayaran lalu tekan Benar.",
        "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening & nama Merchant. Jika informasi telah sesuai tekan Benar.",
      ],
    },
    {
      name: "ATM Bersama",
      steps: [
        "Pada menu utama, pilih Transaksi Lainnya.",
        "Pilih Transfer.",
        "Pilih Antar Bank Online.",
        "Masukkan 013 dan 16 digit No. Rekening.",
        "Masukkan jumlah tagihan yang akan Anda bayar secara lengkap, Pembayaran dengan jumlah tidak sesuai akan otomatis ditolak.",
        "Kosongkan No. Referensi, lalu tekan Benar.",
        "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening & nama Merchant. Jika informasi telah sesuai tekan Benar.",
      ],
    },
    {
      name: "Alto",
      steps: [
        "Pada menu utama, pilih Transaksi Lainnya.	",
        "Pilih Transaksi Pembayaran.",
        "Pilih Lain-lain.",
        "Pilih Pembayaran Virtual Account.",
        "Masukkan 16 digit No. Rekening pembayaran lalu tekan Benar.",
        "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening & nama Merchant. Jika informasi telah sesuai tekan Benar.",
        "Pilih rekening pembayaran Anda dan tekan Benar.",
      ],
    },
  ],
};

export const GOPAYMidtrans: ChannelModel = {
  thumbnail: "gopay sprite",
  type: ChannelType.EWALLET,
  payment_type: "gopay",
  code: "gopay",
  title: "GoPay",
  subtitle: "Pembayaran melalui GoPay",
  additional_text: [
    "Scan kode QR berikut dengan aplikasi Gojek.",
    "Buka Aplikasi Gojek",
  ],
  hows: [
    {
      name: "GoPay",
      steps: [
        "Buka aplikasi Gojek atau e-wallet lain Anda.",
        "Pindai kode QR yang ada pada monitor Anda.",
        "Periksa detail transaksi Anda pada aplikasi, lalu tap tombol Bayar.",
        "Masukkan PIN Anda.",
        "Transaksi Anda telah selesai.",
      ],
    },
  ],
};

export const OVOMidtrans: ChannelModel = {
  thumbnail: "ovo sprite",
  type: ChannelType.EWALLET,
  payment_type: "ovo",
  code: "ovo",
  title: "OVO",
  subtitle: "Pembayaran melalui OVO",
  additional_text: [
    "Scan kode QR berikut dengan aplikasi OVO.",
    "Buka Aplikasi OVO",
  ],
  hows: [
    {
      name: "OVO",
      steps: [
        "Buka aplikasi OVO atau e-wallet lain Anda.",
        "Pindai kode QR yang ada pada monitor Anda.",
        "Periksa detail transaksi Anda pada aplikasi, lalu tap tombol Bayar.",
        "Masukkan PIN Anda.",
        "Transaksi Anda telah selesai.",
      ],
    },
  ],
};

export const SHOPEEMidtrans: ChannelModel = {
  thumbnail: "shopee sprite",
  type: ChannelType.EWALLET,
  payment_type: "shopeepay",
  code: "shopee",
  title: "ShopeePay",
  subtitle: "Pembayaran melalui ShopeePay",
  additional_text: [
    "Klik tombol dibawah untuk menuju aplikasi Shopee",
    "Buka Aplikasi Shopee",
  ],
  hows: [
    {
      name: "ShopeePay",
      steps: [
        "Buka aplikasi Shopee atau e-wallet lain Anda.",
        "Periksa detail transaksi Anda pada aplikasi, lalu tap tombol Bayar.",
        "Masukkan PIN Anda.",
        "Transaksi Anda telah selesai.",
      ],
    },
  ],
};

export const ALFAMARTMidtrans: ChannelModel = {
  thumbnail: "alfamart sprite",
  type: ChannelType.CSTORE,
  code: "alfamart",
  payment_type: "cstore",
  title: "ALFAMART",
  subtitle: "Pembayaran melalui Gerai Alfamart, Alfamidi, atau Dan+Dan.",
  hows: [
    {
      name: "Gerai Alfamart, Alfamidi, atau Dan+Dan",
      steps: [
        "Silakan pergi ke gerai Alfamart, Alfamidi, or Dan+Dan terdekat dan berikanlah nomorKode Pembayaran Anda ke kasir.",
        "Kasir akan mengkonfirmasi transaksi dengan menanyakan jumlah transaksi dan nama merchant.",
        "Konfirmasikan pembayaran Anda ke kasir.",
        "Transaksi Anda berhasil! Anda akan mendapatkan email konfirmasi pembayaran dan simpanlah struk transaksi Anda.",
      ],
    },
  ],
};

export const BCAVAIPaymu: ChannelModel = {
  thumbnail: "bca sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "bca",
  title: "BCA",
  subtitle: "BCA Virtual Account",
  hows: [
    {
      name: "ATM BCA",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BCA Virtual Account”",
        "Masukkan nomor BCA Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BCA Pribadi",
      steps: [
        "Silahkan login pada aplikasi KlikBCA Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke BCA Virtual Account”",
        "Masukkan nomor BCA Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BCA Bisnis",
      steps: [
        "Silahkan melakukan login di KlikBCA Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BCA Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BCA Virtual Account”",
        "Pilih rekening sumber dana dan BCA Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BCA",
      steps: [
        "Silahkan login pada aplikasi BCA Mobile",
        "Pilih “m-BCA”, lalu masukkan kode akses m-BCA",
        "Pilih “m-Transfer”",
        "Lanjut ke “BCA Virtual Account”",
        "Masukkan nomor BCA Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BCA Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const BSIVAIPaymu: ChannelModel = {
  thumbnail: "bsi sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "bsi",
  title: "BSI",
  subtitle: "BSI Virtual Account",
  hows: [
    {
      name: "ATM BSI",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BSI Virtual Account”",
        "Masukkan nomor BSI Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BSI Pribadi",
      steps: [
        "Silahkan login pada aplikasi KlikBSI Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke BSI Virtual Account”",
        "Masukkan nomor BSI Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BSI Bisnis",
      steps: [
        "Silahkan melakukan login di KlikBSI Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BSI Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BSI Virtual Account”",
        "Pilih rekening sumber dana dan BSI Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BSI",
      steps: [
        "Silahkan login pada aplikasi BSI Mobile",
        "Pilih “m-BSI, lalu masukkan kode akses m-BSI",
        "Pilih “m-Transfer”",
        "Lanjut ke “BSI Virtual Account”",
        "Masukkan nomor BSI Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BSI Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const BMIVAIPaymu: ChannelModel = {
  thumbnail: "bmi sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "bmi",
  title: "Bank Muamalat",
  subtitle: "Bank Muamalat Virtual Account",
  hows: [
    {
      name: "ATM BMI",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BMI Virtual Account”",
        "Masukkan nomor BMI Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BMI Pribadi",
      steps: [
        "Silahkan login pada aplikasi KlikBMI Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke BMI Virtual Account”",
        "Masukkan nomor BMI Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BMI Bisnis",
      steps: [
        "Silahkan melakukan login di KlikBMI Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BMI Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BMI Virtual Account”",
        "Pilih rekening sumber dana dan BMI Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BMI",
      steps: [
        "Silahkan login pada aplikasi BMI Mobile",
        "Pilih “m-BMI, lalu masukkan kode akses m-BMI",
        "Pilih “m-Transfer”",
        "Lanjut ke “BMI Virtual Account”",
        "Masukkan nomor BMI Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BMI Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const DanamonVAIPaymu: ChannelModel = {
  thumbnail: "danamon sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "danamon",
  title: "Danamon",
  subtitle: "Danamon Virtual Account",
  hows: [
    {
      name: "ATM Danamon",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening Danamon Virtual Account”",
        "Masukkan nomor Danamon Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik Danamon Pribadi",
      steps: [
        "Silahkan login pada aplikasi Klik Danamon Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Danamon",
        "Pilih “Transfer ke Danamon Virtual Account”",
        "Masukkan nomor Danamon Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik Danamon Bisnis",
      steps: [
        "Silahkan melakukan login di Klik Danamon Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor Danamon Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke Danamon Virtual Account”",
        "Pilih rekening sumber dana dan Danamon Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-Danamon",
      steps: [
        "Silahkan login pada aplikasi Danamon Mobile",
        "Pilih “m-Danamon, lalu masukkan kode akses m-Danamon",
        "Pilih “m-Transfer”",
        "Lanjut ke Danamon Virtual Account”",
        "Masukkan nomor Danamon Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-Danamon Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const BPDBaliVAIPaymu: ChannelModel = {
  thumbnail: "bpd_bali sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "bpd_bali",
  title: "BPD Bali",
  subtitle: "BPD Bali Virtual Account",
  hows: [
    {
      name: "ATM BPD Bali",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BPD Bali Virtual Account”",
        "Masukkan nomor BPD Bali Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BPD Bali Pribadi",
      steps: [
        "Silahkan login pada aplikasi Klik BPD Bali Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer BPD Bali",
        "Pilih “Transfer ke BPD Bali Virtual Account”",
        "Masukkan nomor BPD Bali Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BPD Bali Bisnis",
      steps: [
        "Silahkan melakukan login di Klik BPD Bali Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BPD Bali Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BPD Bali Virtual Account”",
        "Pilih rekening sumber dana dan BPD Bali Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BPD Bali",
      steps: [
        "Silahkan login pada aplikasi BPD Bali Mobile",
        "Pilih “m-BPD Bali, lalu masukkan kode akses m-BPD Bali",
        "Pilih “m-Transfer”",
        "Lanjut ke BPD Bali Virtual Account”",
        "Masukkan nomor BPD Bali Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BPD Bali Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const CIMBVAIPaymu: ChannelModel = {
  thumbnail: "cimb sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "cimb",
  title: "CIMB",
  subtitle: "CIMB Virtual Account",
  hows: [
    {
      name: "ATM CIMB",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening CIMB Virtual Account”",
        "Masukkan nomor CIMB Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik CIMB Pribadi",
      steps: [
        "Silahkan login pada aplikasi Klik CIMB Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer CIMB",
        "Pilih “Transfer ke CIMB Virtual Account”",
        "Masukkan nomor CIMB Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik CIMB Bisnis",
      steps: [
        "Silahkan melakukan login di Klik CIMB Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor CIMB Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke CIMB Virtual Account”",
        "Pilih rekening sumber dana dan CIMB Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-CIMB",
      steps: [
        "Silahkan login pada aplikasi CIMB Mobile",
        "Pilih “m-CIMB, lalu masukkan kode akses m-CIMB",
        "Pilih “m-Transfer”",
        "Lanjut ke CIMB Virtual Account”",
        "Masukkan nomor CIMB Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-CIMB Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const BAGVAIPaymu: ChannelModel = {
  thumbnail: "bag sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "bag",
  title: "BAG",
  subtitle: "BAG Virtual Account",
  hows: [
    {
      name: "ATM BAG",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BAG Virtual Account”",
        "Masukkan nomor BAG Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BAG Pribadi",
      steps: [
        "Silahkan login pada aplikasi Klik BAG Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer BAG",
        "Pilih “Transfer ke BAG Virtual Account”",
        "Masukkan nomor BAG Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BAG Bisnis",
      steps: [
        "Silahkan melakukan login di Klik BAG Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BAG Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BAG Virtual Account”",
        "Pilih rekening sumber dana dan BAG Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BAG",
      steps: [
        "Silahkan login pada aplikasi BAG Mobile",
        "Pilih “m-BAG, lalu masukkan kode akses m-BAG",
        "Pilih “m-Transfer”",
        "Lanjut ke BAG Virtual Account”",
        "Masukkan nomor BAG Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BAG Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const PermataVAIPaymu: ChannelModel = {
  thumbnail: "permata sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "permata",
  title: "Permata",
  subtitle: "Permata Virtual Account",
  hows: [
    {
      name: "ATM Permata",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening Permata Virtual Account”",
        "Masukkan nomor Permata Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik Permata Pribadi",
      steps: [
        "Silahkan login pada aplikasi Klik Permata Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Permata",
        "Pilih “Transfer ke Permata Virtual Account”",
        "Masukkan nomor Permata Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik Permata Bisnis",
      steps: [
        "Silahkan melakukan login di Klik Permata Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor Permata Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke Permata Virtual Account”",
        "Pilih rekening sumber dana dan Permata Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-Permata",
      steps: [
        "Silahkan login pada aplikasi Permata Mobile",
        "Pilih “m-Permata, lalu masukkan kode akses m-Permata",
        "Pilih “m-Transfer”",
        "Lanjut ke Permata Virtual Account”",
        "Masukkan nomor Permata Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-Permata Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const MANDIRIVAIPaymu: ChannelModel = {
  thumbnail: "mandiri sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "mandiri",
  title: "MANDIRI",
  subtitle: "Mandiri Virtual Account",
  echannel: {
    bill_info1: "Payment",
    bill_info2: "Online payment",
  },
  hows: [
    {
      name: "ATM Mandiri",
      steps: [
        "Pada menu utama, pilih Bayar/Beli",
        "Pilih Lainnya",
        "Pilih Multi Payment",
        "Masukkan Kode Perusahaan Ipaymu - 89008",
        "Masukkan Nomer VA (Virtual Account) tertuju",
        "Masukkan nominal pembayaran sesuai dengan tagihan",
        "Konfirmasi pembayaran akan muncul berupa pembayaran ke Ipaymu, nomor VA, dan total tagihan. Pilih YA jika benar",
        "Transaksi selesai",
      ],
    },
    {
      name: "Mobile Banking",
      steps: [
        "Pilih menu Bayar",
        "Pilih E-Commerce",
        "Pilih Penyedia Jasa Perusahaan Ipaymu – 89008",
        "Masukkan Nomer VA (Virtual Account) tertuju",
        "Masukkan nominal pembayaran sesuai dengan tagihan",
        "Konfirmasi pembayaran akan muncul berupa pembayaran ke Ipaymu, nomor VA, dan total tagihan. Pilih ’YA’ jika benar",
        "Transaksi selesai",
      ],
    },
    {
      name: "Internet Banking",
      steps: [
        "Akses ib.bankmandiri.co.id",
        "Masukkan User ID dan PIN, kemudian login",
        "Pilih menu Bayar lalu pilih menu Multi Payment",
        "Pilih Dari Rekening dan pilih rekening Anda",
        "Pilih Penyedia Jasa pilih Ipaymu pada bagian No VA masukkan nomor Virtual Account tertuju",
        "Di bagian Nominal masukkan nominal transaksi untuk order tersebut lalu klik Lanjutkan *(Tidak perlu pilih Simpan di Daftar Pembayaran)",
        "Centang pada bagian Total Tagihan, lalu Jumlah Tagihan akan muncul dan klik Lanjutkan",
        "Input PIN Mandiri Anda dari Token kemudian klik Kirim",
        "Transaksi selesai",
      ],
    },
    {
      name: "ATM Bank Lain",
      steps: [
        "Pilih menu Transfer antar bank",
        "Masukkan Kode Bank Tujuan Mandiri 008 atau bisa melakukan pengecekan terlebih dahulu pada daftar kode bank",
        "Lalu masukkan nomor Virtual Account tertuju lalu pilih Benar",
        "Masukan nominal pembayaran sesuai dengan tagihan lalu pilih Benar",
        "Konfirmasi pembayaran dan pilih Benar",
        "Transaksi selesai",
      ],
    },
  ],
};

export const QRISIPaymu: ChannelModel = {
  thumbnail: "qris sprite",
  type: ChannelType.EWALLET,
  payment_type: "qris",
  code: "qris",
  title: "QRIS iPaymu",
  subtitle: "Untuk semua Bank dan eWallet di Indonesia yang mendukung QRIS",
  hows: [
    {
      name: "Gopay",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
    {
      name: "Shopee",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
    {
      name: "Dana",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
    {
      name: "OVO",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
    {
      name: "BCA",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
  ],
};

export const QRISMidtrans: ChannelModel = {
  thumbnail: "qris sprite",
  type: ChannelType.EWALLET,
  payment_type: "qris",
  code: "qris",
  title: "QRIS",
  subtitle: "Untuk semua Bank dan eWallet di Indonesia yang mendukung QRIS",
  hows: [
    {
      name: "Gopay",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
    {
      name: "Shopee",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
    {
      name: "Dana",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
    {
      name: "OVO",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
    {
      name: "BCA",
      steps: ["Klik Aplikasi", "Scan", "Masukkan Nominal", "Selesai"],
    },
  ],
};

export const ALFAMARTIPaymu: ChannelModel = {
  thumbnail: "alfamart-ipay sprite",
  type: ChannelType.CSTORE,
  payment_type: "cstore",
  code: "alfamart",
  title: "Alfamart",
  subtitle: "Pembayaran melalui Gerai Alfamart",
  hows: [
    {
      name: " Alfamart / Dan+Dan / Lawson",
      steps: [
        "Menuju Kasir Alfamart/Alfamidi/Dan+Dan/Lawson",
        "Buka SMS/Email/WA konfirmasi pembelian yang dikirimkan iPaymu",
        "Informasikan kepada kasir ingin melakukan pembayaran tagihan PLASAMAL",
        "Sebutkan KODE PEMBAYARAN yang diinformasikan di SMS/Email dari iPaymu",
        "Kasir akan menginformasikan nilai tagihan",
        "Pastikan nilai tagihan sesuai dengan transaksi Anda",
        "Bayarkan tagihan tersebut + fee admin Alfamart/Alfamidi//Dan+Dan/Lawson",
        "Transaksi selesai",
      ],
    },
  ],
};

export const INDOMARETIPaymu: ChannelModel = {
  thumbnail: "indomaret sprite",
  type: ChannelType.CSTORE,
  payment_type: "cstore",
  code: "indomaret",
  title: "Indomaret",
  subtitle: "Pembayaran melalui Gerai Indomaret",
  hows: [
    {
      name: "Gerai Indomaret",
      steps: [
        "Menuju Kasir Indomaret",
        "Buka SMS atau Email konfirmasi pembelian yang dikirimkan iPaymu",
        "Informasikan kepada kasir ingin melakukan pembayaran tagihan LINKITA",
        "Sebutkan KODE PEMBAYARAN yang diinformasikan di SMS/Email/WA dari iPaymu",
        "Kasir akan menginformasikan nilai tagihan",
        "Pastikan nilai tagihan sesuai dengan transaksi Anda",
        "Bayarkan tagihan tersebut + fee admin Indomaret",
        "Transaksi selesai",
      ],
    },
  ],
};

export const BNIVAIPaymu: ChannelModel = {
  thumbnail: "bni sprite",
  payment_type: "va",
  code: "bni",
  type: ChannelType.BANK_TRANSFER,
  title: "BNI",
  subtitle: "BNI Virtual Account",
  hows: [
    {
      name: "ATM BNI",
      steps: [
        "Pilih Menu Lainnya lalu pilih menu Transfer",
        "Pilih jenis rekening yang akan digunakan",
        "Pilih Virtual Account Billing",
        "Masukan Nomor Virtual Account tertuju",
        "Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi",
        "Pilih Konfirmasi apabila telah sesuai dengan pesanan dan tagihan Anda, lanjutkan transaksi",
        "Konfirmasi pembayaran ditampilkan pada layar",
      ],
    },
    {
      name: "ATM Bersama",
      steps: [
        "Pilih Transaksi Lainnya",
        "Pilih menu Transfer",
        "Pilih Transfer ke Bank Lain",
        "Masukkan kode bank BNI 009 dan Virtual Account tertuju",
        "Konfirmasi rincian akan tampil di layar, cek dan tekan Ya jika benar",
        "Transaksi selesai",
      ],
    },
    {
      name: "ATM Bank Lain",
      steps: [
        "Pilih menu Transfer Antar Bank atau Transfer online antar bank",
        "Masukan Kode Bank Tujuan Bank BNI 009 atau pilih bank yang dituju yaitu BNI",
        "Masukkan nomor Virtual Account pada kolom rekening tujuan",
        "Masukkan nominal transfer sesuai dengan jumlah pembayaran sesuai dengan tagihan *Nominal yang berbeda tidak dapat diproses",
        "Konfirmasi rincian akan tampil di layar, cek dan tekan Ya jika benar.",
      ],
    },
    {
      name: "Internet Banking",
      steps: [
        "Akses https://ibank.bni.co.id",
        "Masukkan User ID dan Password",
        "Pilih menu Transfer",
        "Pilih Virtual Account Billing",
        "Kemudian masukan nomor Virtual Account tertuju lalu pilih rekening debet yang akan digunakan lalu pilih lanjut",
        "Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi",
        "Masukkan Kode Otentikasi Token",
        "Transaksi selesai",
      ],
    },
    {
      name: "Mobile Banking",
      steps: [
        "Pilih menu Transfer",
        "Pilih menu Virtual Account Billing kemudian pilih Rekening Debet",
        "Masukkan nomor Virtual Account tertuju pada menu input baru",
        "Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi",
        "Konfirmasi transaksi dan masukkan Password Transaksi.",
        "Transaksi selesai",
      ],
    },
  ],
};

export const BRIVAIPaymu: ChannelModel = {
  thumbnail: "bri sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "va",
  code: "bri",
  title: "BRI",
  subtitle: "BRI Virtual Account",
  hows: [
    {
      name: "ATM BRI",
      steps: [
        "Masukkan kartu ATM, input PIN",
        "Pilih menu TRANSAKSI LAIN, kemudian LAINNYA",
        "Pilih menu PEMBAYARAN kemudian pilih BRIVA",
        "Masukkan Nomor Virtual Account tekan BENAR",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Apabila sudah sesuai, tekan YA",
        "Transaksi Anda selesai, simpan bukti transaksi.",
      ],
    },
    {
      name: "IB BRI",
      steps: [
        "Login ke IB BRI",
        "Pilih menu Pembayaran",
        "Pilih menu BRIVA",
        "Kemudian pilih rekening PEMBAYAR",
        "Masukkan Nomor Virtual Account",
        "Masukkan Nominal pembayaran (nominal harus sesuai)",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Masukkan password & Mtoken",
        "Transaksi Anda selesai, simpan bukti transaksi.",
      ],
    },
    {
      name: "BRImo",
      steps: [
        "Login ke Mobile Banking BRI.",
        "Pilih menu Pembayaran",
        "Pilih menu BRIVA",
        "Masukkan Nomor Virtual Account, tekan BENAR",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Apabila sudah sesuai, masukkan PIN",
        "Transaksi Anda selesai, simpan bukti transaksi.",
      ],
    },
    {
      name: "ATM Bank Lain",
      steps: [
        "Masukkan kartu ATM, input PIN",
        "Pilih menu TRANSFER > TRANSFER KE BANK LAIN",
        "Masukkan Tujuan Bank BRI / kode Bank BRI: 002",
        "Masukkan nomor Virtual Account",
        "Masukkan jumlah pembayaran sesuai tagihan",
        "Ikuti instruksi untuk menyelesaikan transaksi",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Masukkan PIN lalu klik LANJUTKAN",
        "Transaksi Anda selesai, simpan bukti transaksi",
      ],
    },
    {
      name: "Mobile Banking Bank Lain",
      steps: [
        "Login ke mobile banking",
        "Daftarkan terlebih dahulu Virtual Account tertuju",
        "Pilih menu TRANSFER > TRANSFER KE BANK LAIN",
        "Masukkan Tujuan Bank BRI / kode Bank BRI: 002",
        "Masukkan nomor Virtual Account",
        "Periksa nama tujuan “IPAYMU [Nama Merchant]”",
        "Lanjutkan sampai no rekening tersimpan",
        "Lanjutkan pembayaran, pilih TRANSFER > TRANSFER KE BANK LAIN",
        "Pilih tujuan transfer sesuai no Virtual Account yang sudah didaftar diatas",
        "Masukkan jumlah pembayaran sesuai tagihan",
        "Ikuti instruksi untuk menyelesaikan transaksi",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Masukkan PIN/password/passcode lalu klik LANJUTKAN",
        "Transaksi Anda selesai, simpan bukti transaksi",
      ],
    },
    {
      name: "Internet Banking Bank Lain",
      steps: [
        "Login ke Internet Banking",
        "Daftarkan terlebih dahulu Virtual Account tertuju",
        "Pilih menu TRANSFER > TRANSFER KE BANK LAIN",
        "Masukkan Tujuan Bank BRI / kode Bank BRI: 002",
        "Masukkan nomor Virtual Account",
        "Periksa nama tujuan “IPAYMU [Nama Merchant]”",
        "Lanjutkan sampai no rekening tersimpan",
        "Lanjutkan pembayaran, pilih TRANSFER > TRANSFER KE BANK LAIN",
        "Pilih tujuan transfer sesuai no Virtual Account yang sudah didaftar diatas",
        "Masukkan jumlah pembayaran sesuai tagihan",
        "Ikuti instruksi untuk menyelesaikan transaksi",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Masukkan PIN/password/passcode/token lalu klik LANJUTKAN",
        "Transaksi Anda selesai, simpan bukti transaksi",
      ],
    },
  ],
};

export const BCAVANicepay: ChannelModel = {
  thumbnail: "bca sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "02",
  code: "CENA",
  title: "BCA",
  subtitle: "BCA Virtual Account",
  hows: [
    {
      name: "ATM BCA",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BCA Virtual Account”",
        "Masukkan nomor BCA Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BCA Pribadi",
      steps: [
        "Silahkan login pada aplikasi KlikBCA Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke BCA Virtual Account”",
        "Masukkan nomor BCA Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BCA Bisnis",
      steps: [
        "Silahkan melakukan login di KlikBCA Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BCA Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BCA Virtual Account”",
        "Pilih rekening sumber dana dan BCA Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BCA",
      steps: [
        "Silahkan login pada aplikasi BCA Mobile",
        "Pilih “m-BCA”, lalu masukkan kode akses m-BCA",
        "Pilih “m-Transfer”",
        "Lanjut ke “BCA Virtual Account”",
        "Masukkan nomor BCA Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BCA Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const GLOBALIfortepay: ChannelModel = {
  thumbnail: "bank-transfer sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "02",
  code: "CENA",
  title: "Virtual Account, QRIS, eWallet, CC",
  subtitle: "Virtual Account, QRIS, eWallet, CC",
  hows: [
    {
      name: "ATM BCA",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BCA Virtual Account”",
        "Masukkan nomor BCA Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BCA Pribadi",
      steps: [
        "Silahkan login pada aplikasi KlikBCA Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke BCA Virtual Account”",
        "Masukkan nomor BCA Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BCA Bisnis",
      steps: [
        "Silahkan melakukan login di KlikBCA Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BCA Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BCA Virtual Account”",
        "Pilih rekening sumber dana dan BCA Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BCA",
      steps: [
        "Silahkan login pada aplikasi BCA Mobile",
        "Pilih “m-BCA”, lalu masukkan kode akses m-BCA",
        "Pilih “m-Transfer”",
        "Lanjut ke “BCA Virtual Account”",
        "Masukkan nomor BCA Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BCA Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const MANDIRIVANicepay: ChannelModel = {
  thumbnail: "mandiri sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "02",
  code: "BMRI",
  title: "MANDIRI",
  subtitle: "Mandiri Virtual Account",
  echannel: {
    bill_info1: "Payment",
    bill_info2: "Online payment",
  },
  hows: [
    {
      name: "ATM Mandiri",
      steps: [
        "Pada menu utama, pilih Bayar/Beli",
        "Pilih Lainnya",
        "Pilih Multi Payment",
        "Masukkan Kode Perusahaan Nicepay - 88049",
        "Masukkan Nomer VA (Virtual Account) tertuju",
        "Masukkan nominal pembayaran sesuai dengan tagihan",
        "Konfirmasi pembayaran akan muncul berupa pembayaran ke Ipaymu, nomor VA, dan total tagihan. Pilih YA jika benar",
        "Transaksi selesai",
      ],
    },
    {
      name: "Mobile Banking",
      steps: [
        "Pilih menu Bayar",
        "Pilih E-Commerce",
        "Pilih Penyedia Jasa Perusahaan Nicepay – 88049",
        "Masukkan Nomer VA (Virtual Account) tertuju",
        "Masukkan nominal pembayaran sesuai dengan tagihan",
        "Konfirmasi pembayaran akan muncul berupa pembayaran ke Ipaymu, nomor VA, dan total tagihan. Pilih ’YA’ jika benar",
        "Transaksi selesai",
      ],
    },
    {
      name: "Internet Banking",
      steps: [
        "Akses ib.bankmandiri.co.id",
        "Masukkan User ID dan PIN, kemudian login",
        "Pilih menu Bayar lalu pilih menu Multi Payment",
        "Pilih Dari Rekening dan pilih rekening Anda",
        "Pilih Penyedia Jasa pilih Ipaymu pada bagian No VA masukkan nomor Virtual Account tertuju",
        "Di bagian Nominal masukkan nominal transaksi untuk order tersebut lalu klik Lanjutkan *(Tidak perlu pilih Simpan di Daftar Pembayaran)",
        "Centang pada bagian Total Tagihan, lalu Jumlah Tagihan akan muncul dan klik Lanjutkan",
        "Input PIN Mandiri Anda dari Token kemudian klik Kirim",
        "Transaksi selesai",
      ],
    },
    {
      name: "ATM Bank Lain",
      steps: [
        "Pilih menu Transfer antar bank",
        "Masukkan Kode Bank Tujuan Mandiri 008 atau bisa melakukan pengecekan terlebih dahulu pada daftar kode bank",
        "Lalu masukkan nomor Virtual Account tertuju lalu pilih Benar",
        "Masukan nominal pembayaran sesuai dengan tagihan lalu pilih Benar",
        "Konfirmasi pembayaran dan pilih Benar",
        "Transaksi selesai",
      ],
    },
  ],
};

export const BNIVANicepay: ChannelModel = {
  thumbnail: "bni sprite",
  payment_type: "02",
  code: "BNIN",
  type: ChannelType.BANK_TRANSFER,
  title: "BNI",
  subtitle: "BNI Virtual Account",
  hows: [
    {
      name: "ATM BNI",
      steps: [
        "Pilih Menu Lainnya lalu pilih menu Transfer",
        "Pilih jenis rekening yang akan digunakan",
        "Pilih Virtual Account Billing",
        "Masukan Nomor Virtual Account tertuju",
        "Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi",
        "Pilih Konfirmasi apabila telah sesuai dengan pesanan dan tagihan Anda, lanjutkan transaksi",
        "Konfirmasi pembayaran ditampilkan pada layar",
      ],
    },
    {
      name: "ATM Bersama",
      steps: [
        "Pilih Transaksi Lainnya",
        "Pilih menu Transfer",
        "Pilih Transfer ke Bank Lain",
        "Masukkan kode bank BNI 009 dan Virtual Account tertuju",
        "Konfirmasi rincian akan tampil di layar, cek dan tekan Ya jika benar",
        "Transaksi selesai",
      ],
    },
    {
      name: "ATM Bank Lain",
      steps: [
        "Pilih menu Transfer Antar Bank atau Transfer online antar bank",
        "Masukan Kode Bank Tujuan Bank BNI 009 atau pilih bank yang dituju yaitu BNI",
        "Masukkan nomor Virtual Account pada kolom rekening tujuan",
        "Masukkan nominal transfer sesuai dengan jumlah pembayaran sesuai dengan tagihan *Nominal yang berbeda tidak dapat diproses",
        "Konfirmasi rincian akan tampil di layar, cek dan tekan Ya jika benar.",
      ],
    },
    {
      name: "Internet Banking",
      steps: [
        "Akses https://ibank.bni.co.id",
        "Masukkan User ID dan Password",
        "Pilih menu Transfer",
        "Pilih Virtual Account Billing",
        "Kemudian masukan nomor Virtual Account tertuju lalu pilih rekening debet yang akan digunakan lalu pilih lanjut",
        "Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi",
        "Masukkan Kode Otentikasi Token",
        "Transaksi selesai",
      ],
    },
    {
      name: "Mobile Banking",
      steps: [
        "Pilih menu Transfer",
        "Pilih menu Virtual Account Billing kemudian pilih Rekening Debet",
        "Masukkan nomor Virtual Account tertuju pada menu input baru",
        "Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi",
        "Konfirmasi transaksi dan masukkan Password Transaksi.",
        "Transaksi selesai",
      ],
    },
  ],
};

export const BRIVANicepay: ChannelModel = {
  thumbnail: "bri sprite",
  type: ChannelType.BANK_TRANSFER,
  payment_type: "02",
  code: "BRIN",
  title: "BRI",
  subtitle: "BRI Virtual Account",
  hows: [
    {
      name: "ATM BRI",
      steps: [
        "Masukkan kartu ATM, input PIN",
        "Pilih menu TRANSAKSI LAIN, kemudian LAINNYA",
        "Pilih menu PEMBAYARAN kemudian pilih BRIVA",
        "Masukkan Nomor Virtual Account tekan BENAR",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Apabila sudah sesuai, tekan YA",
        "Transaksi Anda selesai, simpan bukti transaksi.",
      ],
    },
    {
      name: "IB BRI",
      steps: [
        "Login ke IB BRI",
        "Pilih menu Pembayaran",
        "Pilih menu BRIVA",
        "Kemudian pilih rekening PEMBAYAR",
        "Masukkan Nomor Virtual Account",
        "Masukkan Nominal pembayaran (nominal harus sesuai)",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Masukkan password & Mtoken",
        "Transaksi Anda selesai, simpan bukti transaksi.",
      ],
    },
    {
      name: "BRImo",
      steps: [
        "Login ke Mobile Banking BRI.",
        "Pilih menu Pembayaran",
        "Pilih menu BRIVA",
        "Masukkan Nomor Virtual Account, tekan BENAR",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Apabila sudah sesuai, masukkan PIN",
        "Transaksi Anda selesai, simpan bukti transaksi.",
      ],
    },
    {
      name: "ATM Bank Lain",
      steps: [
        "Masukkan kartu ATM, input PIN",
        "Pilih menu TRANSFER > TRANSFER KE BANK LAIN",
        "Masukkan Tujuan Bank BRI / kode Bank BRI: 002",
        "Masukkan nomor Virtual Account",
        "Masukkan jumlah pembayaran sesuai tagihan",
        "Ikuti instruksi untuk menyelesaikan transaksi",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Masukkan PIN lalu klik LANJUTKAN",
        "Transaksi Anda selesai, simpan bukti transaksi",
      ],
    },
    {
      name: "Mobile Banking Bank Lain",
      steps: [
        "Login ke mobile banking",
        "Daftarkan terlebih dahulu Virtual Account tertuju",
        "Pilih menu TRANSFER > TRANSFER KE BANK LAIN",
        "Masukkan Tujuan Bank BRI / kode Bank BRI: 002",
        "Masukkan nomor Virtual Account",
        "Periksa nama tujuan “NICEPAY [Nama Merchant]”",
        "Lanjutkan sampai no rekening tersimpan",
        "Lanjutkan pembayaran, pilih TRANSFER > TRANSFER KE BANK LAIN",
        "Pilih tujuan transfer sesuai no Virtual Account yang sudah didaftar diatas",
        "Masukkan jumlah pembayaran sesuai tagihan",
        "Ikuti instruksi untuk menyelesaikan transaksi",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Masukkan PIN/password/passcode lalu klik LANJUTKAN",
        "Transaksi Anda selesai, simpan bukti transaksi",
      ],
    },
    {
      name: "Internet Banking Bank Lain",
      steps: [
        "Login ke Internet Banking",
        "Daftarkan terlebih dahulu Virtual Account tertuju",
        "Pilih menu TRANSFER > TRANSFER KE BANK LAIN",
        "Masukkan Tujuan Bank BRI / kode Bank BRI: 002",
        "Masukkan nomor Virtual Account",
        "Periksa nama tujuan “NICEPAY [Nama Merchant]”",
        "Lanjutkan sampai no rekening tersimpan",
        "Lanjutkan pembayaran, pilih TRANSFER > TRANSFER KE BANK LAIN",
        "Pilih tujuan transfer sesuai no Virtual Account yang sudah didaftar diatas",
        "Masukkan jumlah pembayaran sesuai tagihan",
        "Ikuti instruksi untuk menyelesaikan transaksi",
        "Periksa kembali informasi pembayaran dan jumlah tagihan",
        "Masukkan PIN/password/passcode/token lalu klik LANJUTKAN",
        "Transaksi Anda selesai, simpan bukti transaksi",
      ],
    },
  ],
};
